import { OxSurvey } from 'src/components/OxSurvey';
import React from 'react';
import { RouteComponentProps, WindowLocation } from '@reach/router';

type TProps<RouteComponentProps> = RouteComponentProps & {
    location: WindowLocation | undefined;
};

const ArchetypesSurveyPage = ({ location }: TProps<RouteComponentProps>): JSX.Element => {
    return (
        <>
            <OxSurvey location={location} />
        </>
    );
};

export default ArchetypesSurveyPage;
