import { EFontWeight, EFonts } from 'src/config/enums';

import { OxIcon } from '../OxIcon';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    align-items: center;
`;

export const Icon = styled(OxIcon)`
    transform: scale(-1);
    ${fluidSizing([
        { prop: 'height', values: [5, 5, 5] },
        { prop: 'margin-right', values: [5, 5, 5] }
    ])}
`;

export const Text = styled.span`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [10, 10, 10], [18, 18, 18])}
`;
